define([
    'santa-components',
    'lodash',
    'coreUtils',
    'reactDOM',
    'components',
    'skins',
    'googleMap/skins/skins.json',
    'componentsCore'
], function (
    santaComponents,
    _,
    coreUtils,
    ReactDOM,
    components,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    const SUPPORTED_LANGS = ['da', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr'];
    const DEFAULT_ZOOM = 14;
    const LOCALES = {pt: 'pt-BR'};

    const getLanguage = function (props) {
        const languageProperty = props.compProp.language;
        const userLanguage = props.userLanguage;

        const lang = languageProperty === 'userLang' ? userLanguage : languageProperty;
        const language = _.includes(SUPPORTED_LANGS, lang) ? lang : 'en';
        return LOCALES[language] || language;
    };

    function getMapParamsFromProps(props, isExperimentOpen) {
        return coreUtils.googleMapUtils.getGoogleMapsData(props, isExperimentOpen);
    }


    function getIframeUrl(props) {
        const newMapExperiment = props.isExperimentOpen('sv_googleMapsRevamp');
        const mapProps = getMapParamsFromProps(props, newMapExperiment);
        const pickProps = !_.get(mapProps, 'locations') ? ['lat', 'long', 'address', 'addressInfo', 'showZoom', 'showStreetView', 'showMapType', 'fullscreenControl'] : ['defaultLocation', 'showZoom', 'showStreetView', 'showMapType', 'fullscreenControl'];
        const initialProps = _.pick(mapProps, pickProps);
        const initialParams = _.reduce(initialProps, (result, value, key) => result.concat(`&${key}=${value}`), '');
        const clientKeyValueFromExperiment = props.getExperimentValue('googleMapsClientKey');
        const clientKeyParam = clientKeyValueFromExperiment ? `&clientKey=${clientKeyValueFromExperiment}` : '';
        const queryString = `language=${getLanguage(props)}${initialParams}${clientKeyParam}`;
        const newMap = newMapExperiment && Boolean(_.get(mapProps, 'locations'));

        return coreUtils.urlUtils.joinURL(props.santaBase || '', `static/external/googleMap.html?${queryString}&newMap=${newMap ? 'true' : ''}`);
    }

    function getPublicState(state, propsInfo) {
        if (!_.get(propsInfo, 'data.locations')) {
            return {
                zoom: DEFAULT_ZOOM,
                center: {}
            };
        }
        const zoom = _.get(state, ['zoom'], propsInfo.props.zoom || DEFAULT_ZOOM);
        const defaultCenter = propsInfo.data.locations[propsInfo.data.defaultLocation] || {};
        const center = _.get(state, ['center'], {
            longitude: defaultCenter.longitude,
            latitude: defaultCenter.latitude
        });
        return {zoom, center};
    }

    /**
     * @class components.GoogleMap
     * @extends {core.skinBasedComp}
     */
    const googleMap = {
        displayName: 'GoogleMap',
        mixins: [componentsCore.mixins.skinBasedComp, santaComponents.mixins.compStateMixin(getPublicState)],
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                getVisibleMarkers: {methodName: 'getVisibleMarkers'},
                setMapZoom: {methodName: 'setMapZoom', params: ['zoom']},
                setMapCenter: {methodName: 'setMapCenter', params: ['longitude', 'latitude']}
            }
        },
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
            cannotHideIframeWithinRoundedCorners: santaComponents.santaTypesDefinitions.mobile.cannotHideIframeWithinRoundedCorners.isRequired,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired,
            componentPreviewState: santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            getExperimentValue: santaComponents.santaTypesDefinitions.getExperimentValue,
            locations: santaComponents.santaTypesDefinitions.GoogleMap.locations
        },
        getInitialState() {
            const state = {};
            if (this.props.cannotHideIframeWithinRoundedCorners()) {
                state.$corners = 'squared';
            }

            this.restartMap(this.props);

            return _.assign(getPublicState(null, {
                props: this.props.compProp,
                data: this.props.compData
            }), state);
        },
        componentDidMount() {
            this.iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            this.iFrameNode.onload = function () {
                const googleMapsRevampExperimentOpen = this.props.isExperimentOpen('sv_googleMapsRevamp');
                if (googleMapsRevampExperimentOpen && this.props.compData.locations) {
                    window.addEventListener('message', this.googleMapsMessageHandler);
                    const newMapProperties = getMapParamsFromProps(this.props, googleMapsRevampExperimentOpen);
                    this.iFrameNode.contentWindow.postMessage({type: 'SET_INITIAL_LOCATIONS', data: JSON.stringify(newMapProperties)}, '*');
                } else {
                    const params = getMapParamsFromProps(this.props, googleMapsRevampExperimentOpen);
                    this.updateMapParams(params);
                }
            }.bind(this);
        },
        googleMapsMessageHandler(message) {
            try {
                const messageData = JSON.parse(message.data);
                if (messageData.type === 'MAP_CLICKED') {
                    this.onMapClicked(messageData.data);
                } else if (messageData.type === 'MARKER_CLICKED') {
                    this.onMarkerClicked(messageData.data);
                } else if (messageData.type === 'ZOOM_UPDATED') {
                    this.zoomChanged(messageData.data);
                } else if (messageData.type === 'CENTER_UPDATED') {
                    this.centerChanged(messageData.data);
                }
            } catch (e) {
                return;
            }
        },
        componentWillUnmount() {
            window.removeEventListener('message', this.googleMapsMessageHandler);
        },
        setMapZoom(zoom, resolveSdk) {
            this.setState({zoom});
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'SET_ZOOM_FINISHED') {
                            resolve();
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'SET_ZOOM', data: zoom}, '*');
            }).then(() => {
                resolveSdk();
            });
        },
        setMapCenter(longitude, latitude, resolveSdk) {
            this.setState({center: {
                longitude,
                latitude
            }});
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'SET_CENTER_FINISHED') {
                            resolve(data.data);
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'SET_CENTER', data: JSON.stringify({longitude, latitude})}, '*');
            }).then(() => {
                resolveSdk();
            });
        },
        centerChanged(center) {
            this.setState({center});
        },
        zoomChanged(zoom) {
            this.setState({zoom});
        },
        getVisibleMarkers(resolveSdk) {
            new Promise(resolve => {
                window.addEventListener('message', message => {
                    try {
                        const data = JSON.parse(message.data);
                        if (data.type === 'MARKERS') {
                            resolve(data.data);
                        }
                    } catch (e) {
                        return;
                    }
                });
                const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
                iFrameNode.contentWindow.postMessage({type: 'GET_MARKERS'}, '*');
            }).then(value => {
                resolveSdk(value);
            });
        },
        onMapClicked(mapData) {
            this.handleAction(coreUtils.siteConstants.ACTION_TYPES.GOOGLE_MAP_CLICKED, mapData);
        },
        onMarkerClicked(markerData) {
            markerData = _.assign(markerData, _.pick(markerData.location, ['longitude', 'latitude']));
            this.handleAction(coreUtils.siteConstants.ACTION_TYPES.GOOGLE_MAP_MARKER_CLICKED, markerData);
        },
        componentWillReceiveProps(newProps) {
            const googleMapsRevampExperiment = this.props.isExperimentOpen('sv_googleMapsRevamp');
            const newMapProperties = getMapParamsFromProps(newProps, googleMapsRevampExperiment);
            if (!_.isEqual(this.currentMapProperties, newMapProperties)) {
                if (getLanguage(this.props) !== getLanguage(newProps)) {
                    this.restartMap(newProps);
                    return;
                }
                this.currentMapProperties = newMapProperties;
                if (!googleMapsRevampExperiment) {
                    this.updateMapParams(this.currentMapProperties);
                    return;
                }
                const shouldKeepMarkers = this.shouldKeepMarkers(newProps.compData.locations);
                this.updateMapParams(this.currentMapProperties, shouldKeepMarkers);
            }
        },
        shouldKeepMarkers(newLocations) {
            return _.isEqual(this.props.compData.locations, newLocations);
        },
        updateMapParams(msg, shouldKeepMarkers) {
            const iFrameNode = ReactDOM.findDOMNode(this.refs.iframe);
            iFrameNode.contentWindow.postMessage(JSON.stringify(_.assign({}, msg, {shouldKeepMarkers: !!shouldKeepMarkers})), '*');
        },
        restartMap(props) {
            this.iframeUrl = getIframeUrl(props);
            this.a11yTitle = coreUtils.translationsLoader.getTranslation('component_label', getLanguage(props), 'COMPONENT_LABEL_googleMapsTitle');
        },
        getSkinProperties() {
            return {
                '': {
                    tabIndex: 0,
                    title: this.a11yTitle,
                    'aria-label': this.a11yTitle
                },
                'mapContainer': {
                    key: 'mapContainer',
                    children: [
                        santaComponents.utils.createReactElement('iframe', {
                            ref: 'iframe',
                            'data-src': this.iframeUrl,
                            width: '100%',
                            height: '100%',
                            frameBorder: '0',
                            allowFullScreen: true,
                            scrolling: 'no',
                            title: this.a11yTitle,
                            'aria-label': this.a11yTitle
                        })
                    ]
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.GoogleMap', googleMap);
    components.translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.GoogleMap',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'language']));

    skinsPackage.skinsMap.addBatch(skinsJson);

    return googleMap;
});
